import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'
import { MetaTagProps } from '../../types'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../ErrorComponent'

const MetaTag = ({
  title,
  description,
  slug,
  featureImageTitle,
  featureImageDescription,
  openGraphTitle,
  openGraphDescription,
  openGraphImage,
  openGraphImageAlt,
  openGraphImageType,
  openGraphImageWidth,
  openGraphImageHeight,
}: MetaTagProps) => {
  const router = useRouter()

  const domain = process.env.FRONTEND_URL
  const path = router.asPath.replace(/\?.*$/, '')
  const url = domain + path
  const { i18n } = useTranslation('common')
  return (
    <ErrorBoundary errorComponent={({ error }) => ErrorComponent('Metatag', error)}>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <link rel="canonical" key="canonical" href={url} />
        <link rel="alternate" hrefLang="x-default" href={url} />
        {i18n.language &&
          i18n.options.supportedLngs &&
          i18n.options.supportedLngs.map(
            (language) =>
              language !== 'cimode' && (
                <link
                  key={language}
                  rel="alternate"
                  hrefLang={language}
                  href={`${domain}/${language}${path}`}
                />
              )
          )}
        <meta name="title" key="title" content={title} />
        <meta name="description" key="description" content={description} />
        {slug && <meta name="slug" key="slug" content={slug} />}
        {featureImageTitle && featureImageDescription && (
          <>
            <meta property="og:image:title" key="og:image:title" content={featureImageTitle} />
            <meta
              property="og:image:description"
              key="og:image:description"
              content={featureImageDescription}
            />
          </>
        )}
        {/* https://developers.facebook.com/docs/sharing/best-practices/ */}
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:url" key="og:url" content={url} />
        <meta property="og:title" key="og:title" content={openGraphTitle} />
        <meta property="og:description" key="og:description" content={openGraphDescription} />
        <meta property="og:image" key="og:image" content={domain + openGraphImage} />
        <meta property="og:image:alt" key="og:image:alt" content={openGraphImageAlt} />
        <meta
          property="og:image:type"
          key="og:image:type"
          content={openGraphImageType ?? 'image/jpeg'}
        />
        <meta
          property="og:image:width"
          key="og:image:width"
          content={(openGraphImageWidth ?? 1200).toString()}
        />
        <meta
          property="og:image:height"
          key="og:image:height"
          content={(openGraphImageHeight ?? 800).toString()}
        />
        {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
        <meta property="twitter:card" key="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" key="twitter:site" content="@simplybookcom" />
      </Head>
    </ErrorBoundary>
  )
}

export default MetaTag
