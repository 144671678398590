import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';

const FormSeats = ({ vType, setSeats}) => {

  const [numberofSeats, setNumberofSeats]= useState('Number of Seats')

  const onChange = (obj) => {
    if(obj) {
      setSeats(obj?.value)
      setNumberofSeats(obj?.label)
    }else {
      setSeats('')
    }
  }

    let newSeats = [];
    const minValue = 2;
    const maxValue = 9;

    for (let i = minValue; i <= maxValue; i++) {
      let seat = {
        value: i,
        label: i.toString()
      }
      newSeats.push(seat)
    }

    return (
      <div className='categories_container'>
            <Select
              value={numberofSeats}
              onChange={onChange}
              options={newSeats}
              className=""
              placeholder = {numberofSeats}
            />
      </div>
    )
}

export default FormSeats;
