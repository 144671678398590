import React, { useState } from 'react';
import { TransmissionTypes } from '../../types/index'
import Select from 'react-select';

const FormTransmission = ({ vType, setTransmission}) => {
  const [tranmissionType, setTransmissionType]= useState('Transmission Type')


  const onChange = (obj) => {
    if(obj) {
      setTransmission(obj?.value)
      setTransmissionType(obj.label)
    }else {
      setTransmission('')
    }
  }

  let transmissionOptions = [{ value: 'manual', label: TransmissionTypes.MANUAL },{ value: 'auto', label: TransmissionTypes.AUTO }];

    return (
      <div className='categories_container'>
            <Select
              value={tranmissionType}
              onChange={onChange}
              options={transmissionOptions}
              className=""
              placeholder = {tranmissionType}
            />
      </div>
    )

}

export default FormTransmission;
