import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import { FuelTypes } from '../../types/index'

const FormFuel = ({ vType, setFuel}) => {

  const [fuelType, setFuelType] = useState('Fuel Type')

  const onChange = (obj) => {
    if(obj) {
      setFuel(obj?.value)
      setFuelType(obj.label)
    }else {
      setFuel('')
    }
  }

  let fuelOptions = [{ value: 'benzine', label: FuelTypes.BENZINE },{ value: 'diesel', label: FuelTypes.DIESEL },{ value: 'LPG', label: FuelTypes.LPG }, { value: 'gas', label: FuelTypes.GAS }, { value: 'electric', label: FuelTypes.ELECTRIC },{ value: 'hybrid', label: FuelTypes.HYBRID }];
  
  return (
    <div className='categories_container'>
            <Select
              value={fuelType}
              onChange={onChange}
              options={fuelOptions}
              className=""
              placeholder = {fuelType}
            />
     </div>
    )
}

export default FormFuel;
