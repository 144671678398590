import { MetaTagProps, Page } from '../../types'

type MetaTagRecord = Record<Page, MetaTagProps>

//Delete this file once all pages meta tags are shifted into graph cms
const metaTagsData: MetaTagRecord = {

  'mainPage': {
    title: "Simplybook.com- Car, ATV & Motorbike rentals in Greece",
    description: "Hire a car, scooter or quad from reliable local rentals in Greece. Search, compare and book your vehicle fast & easy! Free cancellation up to 1 week prior.",
    openGraphTitle: "Book a vehicle & enjoy your next holiday in Greece!",
    openGraphDescription: "Get the best services from local agencies at competitive prices",
    openGraphImage: "/images/og/holiday-car-2.jpeg",
    openGraphImageAlt: "a red car with the trunk full of luggage is at the top of a cliff overlooking the sea on a sunny day",
  },

  "partnerPage": {
    title: "List your rental company on Simplybook for more online bookings",
    description: "We partner with small, local car & motorbike rental companies. With no subscriptions and the lowest commission, you set your prices & policies, we do the rest.",
    openGraphTitle: "Join the first vehicle rental marketplace in Greece!",
    openGraphDescription: "Designed for the needs of the local, family-run car rentals",
    openGraphImage: "/images/og/become_partner_illustration.jpg",
    openGraphImageAlt: "illustration of a smartphone with a map, motorcycle on the right and a credit card on the left",
  },

  'faq': {
    title: "Rent a vehicle in Greece FAQs - Simplybook.com",
    description: "Frequently Asked Questions and anything you need to know about your car & motorbike hire in Greece. Find information about age limits, documentation & more.",
    openGraphTitle: "FAQ about renting a vehicle in Greece",
    openGraphDescription: "Everything you need to know about your rental services",
    openGraphImage: "/images/og/FAQ-1200x800.jpg",
    openGraphImageAlt: "frequently asked questions written on a notepad placed around lighting bulbs"
  },

  'blog': {
    title: "All you need to know for your next vacation in Greece",
    description: "The travel blog about Greece created by Simplybook.com, the car & motorbike rental comparison website for local vehicle agencies in Greece!",
    openGraphTitle: "All you need to know for your next vacation in Greece!",
    openGraphDescription: "A travel blog with valuable tips & information!",
    openGraphImage: "/images/og/santorini-flag.jpg",
    openGraphImageAlt: "a whitewashed terrace looking at the sea with the Greek flag in Santorini"
  },

  'aboutUs': {

    title: "In Simplybook, we support local car & moto rentals in Greece",
    description: "We provide small vehicle rentals with the online presence they deserve. Our booking platform connects local businesses with everyone who shares our vision.",
    openGraphTitle: "We support the local, family-run car rentals in Greece",
    openGraphDescription: "Our booking platform connects them with all who share our principles",
    openGraphImage: "/images/og/about_us_og.jpg",
    openGraphImageAlt: "car keys with Simplybook key holder on a wooden table next to the sea",
    openGraphImageHeight: 1080

  },

  'termsOfUsage': {
    title: "Simplybook - Terms of use",
    description: "The terms and conditions govern your access & use of our platform. By accessing and using it, you confirm you have read, and agreed to the terms and conditions.",
    openGraphTitle: "Simplybook - Terms of use",
    openGraphDescription: "Conditions about the access & use of our platform ",
    openGraphImage: "/images/og/about-us-1200x1080.jpg",
    openGraphImageAlt: "an illustration of a blue car with a green tick on the top right following simplybook written in blue letters",
    openGraphImageHeight: 1080
  }
  ,

  'privacyPolicy': {
    title: "Simplybook.com - Privacy policy",
    description: "In Simplybook we collect information that is essential to process your booking. We only keep it for the time needed to provide you with your vehicle rental & for the period required by legal obligations",
    openGraphTitle: "Simplybook.com - Privacy policy",
    openGraphDescription: "We collect information essential to your booking's process",
    openGraphImage: "/images/og/about-us-1200x1080.jpg",
    openGraphImageAlt: "simplybook logo, an illustration of a blue car with a green tick on the top right",
    openGraphImageHeight: 1080
  }
  ,
  'motos': {
    title: "Rent scooters, motorbikes & motorcycles in Greece",
    description: "All you need to know about hiring a bike in Greece: Simplybook partners only with local, family-run agencies in many Greek destinations!",
    openGraphTitle: "Scooters, Motorbikes & Motorcycles",
    openGraphDescription: "Everything you need to know about renting a bike in Greece",
    openGraphImage: "/images/og/scooter_woman_greece.jpg",
    openGraphImageAlt: "outdoor picture of a woman posing next to a scooter on a greek street"
  }
  ,
  'atvs': {
    title: "Rent ATVs, quads & buggies in Greece",
    description: "All you need to know about hiring an all terrain in Greece. Simplybook partners only with local, family-run agencies in many Greek destinations!",
    openGraphTitle: "ATVs, Quads & Buggys",
    openGraphDescription: "All you need to know about renting an all-terrain in Greece",
    openGraphImage: "/images/og/atv_landing.jpg",
    openGraphImageAlt: "woman in blue dress on a quad bike looking at the sea"
  }
  ,
  'cars': {
    title: "Rent a car from small local companies in Greece",
    description: "All you need to know about hiring a car in Greece. Simplybook partners only with local, family-run agencies in many Greek destinations!",
    openGraphTitle: "Cars, SUVs & Minivans",
    openGraphDescription: "All you need to know about renting on your holiday in Greece	",
    openGraphImage: "/images/og/holidays_car.jpg",
    openGraphImageAlt: "people in a convertible rental car with their hands up in the air"
  }
  ,

}

export default metaTagsData
