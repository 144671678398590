import React, { useEffect, useState } from 'react'
import Categories from '../../components/Categories'
import DateTimePicker from '../../components/DateTimePicker'
import moment from 'moment'
import { connect } from 'react-redux'
import { clearReturn, setReturnLocation } from '../../actions/searchActions'
import Router, { useRouter } from 'next/router'
import 'react-toggle/style.css'
import FormSeats from './FormSeats'
import FormFuel from './FormFuel'
import FormTransmission from './FormTransmission'
import GooglePlacesInput from '../../components/GooglePlacesInput'
import { useTranslation } from 'next-i18next'
import { setVType } from '../../actions/sessionActions'
import { getWidgetMode } from '../../utils/atoms'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../components/ErrorComponent'

function Search({
                  type,
                  pickupLocation,
                  hasReturnLocation,
                  returnLocation,
                  pickupDateTime,
                  returnPickupTime,
                  setVType,
                  setReturnLocation,
                  clearReturn,
                  category,
                }) {
  const [showReturn, setShowReturn] = useState(false)
  const [enableAdvanceSearch, setEnableAdvanceSearch] = useState(false)
  const [seats, setSeats] = useState(0)
  const [fuel, setFuel] = useState('')
  const [transmission, setTransmission] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const { t, ready } = useTranslation()
  const router = useRouter()
  const widgetMode = getWidgetMode()
  useEffect(() => {
    (async function runEffects() {
      if (hasReturnLocation) {
        setShowReturn(hasReturnLocation)
      }
    })()
  }, [])

  useEffect(() => {
    if (setVType) {
      setVType(type)
    }
  }, [type])

  const onChangeReturnLocation = () => {
    clearReturn()
    setShowReturn(!showReturn)
    setReturnLocation(!showReturn)
  }

  const resetAdvanceSearch = () => {
    if (!enableAdvanceSearch) {
      setEnableAdvanceSearch(true)
    } else {
      setEnableAdvanceSearch(false)
      setTransmission('')
      setSeats(0)
      setPaymentMethod('')
      setFuel('')
    }
  }

  const isValidSearch = () => {
    let isReturnValid: boolean
    if (hasReturnLocation) {
      isReturnValid = !!returnLocation
    } else {
      isReturnValid = true
    }

    return !!pickupLocation && !!pickupDateTime && !!returnPickupTime && isReturnValid
  }

  const search = () => {
    const startDateTime = `start=${moment(pickupDateTime).format('YYYY-MM-DDTHH:mm:ss')}`
    const endDateTime = `end=${moment(returnPickupTime).format('YYYY-MM-DDTHH:mm:ss')}`
    const fuelVal = fuel ? `&fuel=${fuel}` : ''
    const transmissionValue = transmission ? `&transmission=${transmission}` : ''
    const seatsVal = seats ? `&seats=${seats}` : ''
    const pickupAddress = pickupLocation.formatted_address.split(' ').join('-')
    const returnAddress = returnLocation ? returnLocation.formatted_address.split(' ').join('-') : pickupLocation.formatted_address.split(' ').join('-')
    const creditCardVal = enableAdvanceSearch && (paymentMethod != '' && paymentMethod != 'both') ? (`&creditCardNeeded=${(paymentMethod === 'credit')}`) : ''
    let urlSearch = widgetMode ? `/widget/available/${router.query.rentalId}/${pickupLocation.lng}/${pickupLocation.lat}` : `/available/${pickupLocation.lng}/${pickupLocation.lat}`

    if (hasReturnLocation && returnLocation) {
      urlSearch += `/${returnLocation.lng}/${returnLocation.lat}?pickup=${pickupAddress}&dropoff=${returnAddress}&${startDateTime}&${endDateTime}&category=${category.toLowerCase()}${transmissionValue}${fuelVal}${seatsVal}${creditCardVal}`
    } else {
      urlSearch += `/${pickupLocation.lng}/${pickupLocation.lat}?pickup=${pickupAddress}&${startDateTime}&${endDateTime}&category=${category.toLowerCase()}${transmissionValue}${fuelVal}${seatsVal}${creditCardVal}`
    }
    Router.push(urlSearch)
  }

  let label = 'Search'

  if (type) {
    if (type === 'Car') {
      label = t('SEARCH CAR')
    }
    if (type === 'Moto') {
      label = t('SEARCH MOTO')
    }
    if (type === 'ATV') {
      label = t('SEARCH ATV')
    }
  }

  return (
    <ErrorBoundary
      errorComponent={({ error }) => ErrorComponent( 'SearchIndex' , error )}>
      <>
        {ready &&
          <div className="search_container">

            <GooglePlacesInput pickupLocation={pickupLocation} locationStatus={'pickup'}
                               returnLocation={returnLocation} />
            <div className="checkbox_container">
              <label htmlFor="dropoff-checkbox">{t('Drop-off at different location')}</label>
              <input id="dropoff-checkbox" type="checkbox" onChange={onChangeReturnLocation} checked={showReturn} />
            </div>
            {showReturn && (
              <GooglePlacesInput returnLocation={returnLocation} locationStatus={'return'}
                                 pickupLocation={pickupLocation} />
            )}

            <Categories vType={type} />

            <DateTimePicker pickupDateTime={pickupDateTime} returnPickupTime={returnPickupTime} />
            <label htmlFor="advanced-search-checkbox"
                   className="checkbox_container">{t('Advanced Search Options')}<input id="advanced-search-checkbox"
                                                                                       onChange={() => {
                                                                                         resetAdvanceSearch()
                                                                                       }} type="checkbox"
                                                                                       defaultChecked={enableAdvanceSearch} /></label>

            {enableAdvanceSearch && <>
              {type === 'Car' && <FormTransmission vType={type} setTransmission={setTransmission} />}

              {type === 'Car' && <FormSeats vType={type} setSeats={setSeats} />}

              {<FormFuel vType={type} setFuel={setFuel} />}

              <div className="form-group">
                <div className="checkbox_container">{t('Credit Card')}  </div>
                <div className="checkbox_container" onChange={(event) => { // @ts-ignore
                  setPaymentMethod(event.target.value)
                }}>
                  <input type="checkbox" value="credit" defaultChecked={paymentMethod === 'credit'} />{t('Needed')}
                  <input type="checkbox" value="debit"
                         defaultChecked={paymentMethod === 'debit'} />{t('Not Needed')}
                </div>
              </div>
            </>}
            <div className="button-form-container">
              <button className="btn btn-primary" disabled={!isValidSearch()} onClick={search}>
                {label}
              </button>
            </div>
          </div>}
      </>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  pickupLocation: state.searchReducer.pickupLocation,
  hasReturnLocation: state.searchReducer.hasReturnLocation,
  returnLocation: state.searchReducer.returnLocation,
  pickupDateTime: state.searchReducer.pickupDateTime,
  returnPickupTime: state.searchReducer.returnPickupTime,
  category: state.searchReducer.category,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setVType: (payload) => dispatch(setVType(payload)),
    setReturnLocation: (payload) => dispatch(setReturnLocation(payload)),
    clearReturn: () => dispatch(clearReturn()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
