import {
  ADD_PICKUP_LOCATION,
  ADD_PICKUP_COORDS,
  ADD_PICKUP_FORMATTED_ADDRESS,
  PICKUP_CLEAR,
  SET_RETURN_LOCATION,
  ADD_RETURN_LOCATION,
  ADD_RETURN_COORDS,
  ADD_RETURN_FORMATTED_ADDRESS,
  RETURN_CLEAR,
  CHANGE_CATEGORY,
  ADD_PICKUP_DATETIME,
  ADD_RETURN_DATETIME,
} from './types'

export function addPickupLocation(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_PICKUP_LOCATION, payload })
  }
}

export function addPickupCoords(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_PICKUP_COORDS, payload })
  }
}

export function addPickupFormattedAddress(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_PICKUP_FORMATTED_ADDRESS, payload })
  }
}

export function clearPickup() {
  return function (dispatch) {
    dispatch({ type: PICKUP_CLEAR })
  }
}

export function setReturnLocation(payload) {
  return function (dispatch) {
    dispatch({ type: SET_RETURN_LOCATION, payload })
  }
}

export function addReturnLocation(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_RETURN_LOCATION, payload })
  }
}

export function addReturnCoords(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_RETURN_COORDS, payload })
  }
}

export function addReturnFormattedAddress(payload) {
  return function (dispatch) {
    dispatch({ type: ADD_RETURN_FORMATTED_ADDRESS, payload })
  }
}

export function clearReturn() {
  return function (dispatch) {
    dispatch({ type: RETURN_CLEAR })
  }
}

export function addCategory(payload) {
  return function (dispatch) {
    dispatch({ type: CHANGE_CATEGORY, payload })
  }
}

export function addPickupDateTime(payload) {
  return function (dispatch) {
    // console.debug('Search actions addPickupDateTime:', { payload })
    dispatch({ type: ADD_PICKUP_DATETIME, payload })
  }
}

export function addReturnDateTime(payload) {
  return function (dispatch) {
    // console.debug('Search actions addReturnDateTime:', { payload })
    dispatch({ type: ADD_RETURN_DATETIME, payload })
  }
}
