import React from "react";
import { MetaTagProps } from "../../types";
import MetaTag from "./MetaTag";
import metaTagsData  from './metatagsData'

export const MainPageMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.mainPage} />
  );
};

export const PartnerPageMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.partnerPage} />
  );
};

export const AboutUsMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.aboutUs} />
  );
};
export const FAQMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.faq} />
  );
};

export const BlogMetaTags = (props: { metaContent: MetaTagProps }) => {
  return (
    <MetaTag {...props.metaContent} />
  );
};

export const BlogCategoriesTag = () => {
  return (
    <MetaTag {...metaTagsData.blog} />
  );
};

export const PrivacyPolicyMetaTag = () => {
  return (

    <MetaTag {...metaTagsData.privacyPolicy} />
  );
};

export const TermsOfUsageMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.termsOfUsage} />
  );
};

export const ATVMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.atvs} />
  );
};

export const CarMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.cars} />
  );
};

export const MotosMetaTag = () => {
  return (
    <MetaTag {...metaTagsData.motos} />
  );
};

