import moment from 'moment-timezone';

export function getInitialDate() {
  const date = new Date();
  const greekTime = moment.tz(date, 'Europe/Athens');
  const hours = greekTime.hours();

  const newDate = hours < 12 ? greekTime.add(1, 'days').toDate() : greekTime.add(2, 'days').toDate();
  return newDate;
}

export function getDropOffMinDate  (startDate:Date | null) {
  let date = new Date()
  if (startDate == null) {
    date.setHours(date.getHours() + 24)

  } else {
    date.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

    date.setHours(date.getHours() + 24)
  }
  // console.debug(date)
  return date
}
