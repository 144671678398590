import React from 'react'
import { connect } from 'react-redux'
import { VehicleTypes } from '../../types'
import Search from '../../containers/Search'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useTranslation } from 'next-i18next'


function SearchTabs({ vtype }) {
  const { t, ready } = useTranslation()
  const preSelectedVehicle = vtype || VehicleTypes.CAR
  // const { router: { query: { preSelectedVehicle } } } = props
  const defIndex = preSelectedVehicle && preSelectedVehicle === VehicleTypes.MOTO ? 1 :
    (preSelectedVehicle === VehicleTypes.ATV ? 2 : 0) //(preSelectedVehicle === VehicleTypes.CAR ? 2 : 0))
  return (
    <> {ready &&
      <Tabs defaultIndex={defIndex}>
        <TabList>
          <Tab>{t('CARS')}</Tab>
          <Tab>{t('MOTOS')}</Tab>
          <Tab>{t('ATVs')}</Tab>
        </TabList>
        <TabPanel>
          <Search type="Car" />
        </TabPanel>
        <TabPanel>
          <Search type="Moto" />
        </TabPanel>
        <TabPanel>
          <Search type="ATV" />
        </TabPanel>
      </Tabs>
    }
    </>
  )
}

const mapStateToProps = (state) => ({
  vtype: state.sessionReducer.vtype,
})

export default connect(mapStateToProps)(SearchTabs)
