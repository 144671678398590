import { useState, useEffect } from 'react'

const useCurrentUrl = () => {
  const [currentUrl, setCurrentUrl] = useState<string | undefined>(typeof window !== 'undefined' ? window.location.href : undefined)

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  // console.debug('useCurrentUrl', { currentUrl })
  return currentUrl
}

export default useCurrentUrl
